import { FC } from 'react';
import { useStyles } from './UploadProgressBar.styles';
import { Box } from '@mui/system';
import { useTypedSelector } from '../../../store/store';
import { UploadProcessStep } from '../../../store/storeModels';
import { CircularProgress, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { UploadProgressStrings } from '../../localization/en';
import { SuccessUploadIcon } from '../../assets/SuccessUploadIcon';
// import { WeakWifiIcon } from '../../assets/WeakWifiIcon';
import { useBlockNavigation } from '../../hooks/useBlockNavigation';
import { RouteLeavingModal } from '../RouteLeavingModal/RouteLeavingModal';
import { UploadPausedIcon } from '../../assets/UploadPausedIcon';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';

interface Props {
  forceShowUploadComplete?: boolean;
}

export const UploadProgressBar: FC<Props> = ({ forceShowUploadComplete }) => {
  const classes = useStyles({ color: '#2D11AD' });
  const { currentStep, uploadingProgress, isUploadError } = useTypedSelector((state) => state.upload);
  const isUploadComplete = currentStep === UploadProcessStep.Uploaded || forceShowUploadComplete;
  const { pathname } = useLocation();
  const isSharePage = pathname.includes(AppRoutes.Share);
  const { confirmNavigation, cancelNavigation, pendingNavigation } = useBlockNavigation({
    shouldBlock: !isUploadComplete || false,
  });
  console.log('uploadingProgress', uploadingProgress);
  return (
    <Box className={classes.container}>
      <RouteLeavingModal
        open={!!pendingNavigation}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
        titleText="title text"
        contentText="content text"
      />
      <Box
        className={classes.progressWrapper}
        sx={{
          border: isUploadError ? `1.5px solid #B42318` : `1.5px solid ${'#2D11AD'} `,
          background: '#fff',
        }}
      >
        {!isUploadComplete ? (
          isUploadError ? (
            <Box className={classes.uploadErrorWrapper}>
              <UploadPausedIcon />
              <Box ml="16px">
                <Typography className={classes.uploadFailText}>{UploadProgressStrings.VideoUploadFailed}</Typography>
                {/* <Typography className={classes.weakConnectText}>
                  {UploadProgressStrings.WeakConnectivityDetected}
                </Typography> */}
              </Box>
            </Box>
          ) : (
            <Box display={'flex'}>
              <Box className={classes.wrapper}>
                <CircularProgress
                  variant={!uploadingProgress ? 'indeterminate' : 'determinate'}
                  value={uploadingProgress}
                  sx={{
                    color: '#2D11AD',
                    marginRight: '19px',
                  }}
                  size={44}
                />
                <Box className={classes.iconCenter}>
                  <ArrowUpwardIcon className={classes.iconPulse} />
                </Box>
              </Box>
              <Box display="block">
                <Typography className={classes.uploadText}>{UploadProgressStrings.VideoUploadInProgress}</Typography>
                <Typography
                  className={classes.keepOpenText}
                  sx={{
                    color: '#475467',
                  }}
                >
                  {UploadProgressStrings.KeepThisTabOpen}
                </Typography>
              </Box>
            </Box>
          )
        ) : (
          <>
            <SuccessUploadIcon color={'#2D11AD'} />
            <Box ml="16px">
              <Typography className={classes.uploadCompleteText}>
                {isSharePage
                  ? UploadProgressStrings.VideoUploadCompleteShare
                  : UploadProgressStrings.VideoUploadComplete}
              </Typography>
              <Typography className={classes.thanksText}>
                {isSharePage ? UploadProgressStrings.ThanksForUploadingShare : UploadProgressStrings.ThanksForUploading}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
