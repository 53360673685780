import { makeStyles } from '@mui/styles';

export const useAnimatedTextStyles = makeStyles(() => ({
  buttonLaunch: {
    animation: '$launchAway 600ms ease-in forwards',
  },
  '@keyframes launchAway': {
    '0%': {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(-100px) scale(0.8)',
    },
  },
  buttonPopRight: {
    opacity: 0,
    transform: 'translateX(40px)',
    animation: '$popRight 0.9s ease-out forwards',
  },
  '@keyframes popRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(40px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  buttonZoomBounce: {
    opacity: 0,
    animation: '$zoomBounce 0.8s ease-out forwards',
  },
  '@keyframes zoomBounce': {
    '0%': {
      transform: 'scale(0.5)',
      opacity: 0,
    },
    '60%': {
      transform: 'scale(1.05)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
  cloudyFadeOutText: {
    animation: '$cloudyFadeOutLeft 1.5s ease-out forwards',
    opacity: 1,
    fontSize: 'clamp(24px, 9vw, 48px)',
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'normal',
  },

  '@keyframes cloudyFadeOutLeft': {
    '0%': {
      opacity: 1,
      filter: 'blur(0)',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      filter: 'blur(10px)',
      // transform: 'translateX(-40px)',
    },
  },
  fadingText: {
    animation: '$cloudyFadeLeft 1s ease-out forwards',
    opacity: 0,
  },
  inspireText: {
    fontFamily: `'Fredoka', sans-serif`,
    whiteSpace: 'normal',
    fontSize: 'clamp(24px, 9vw, 48px)',
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
  },
  wordSpan: {
    display: 'inline-block',
    opacity: 0,
    animationName: '$fadeInWord',
    animationDuration: '0.6s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    marginRight: '8px',
  },
  '@keyframes fadeInWord': {
    from: { opacity: 0, transform: 'translateY(10px)', filter: 'blur(4px)' },
    to: { opacity: 1, transform: 'translateY(0)', filter: 'blur(0)' },
  },
  '@keyframes cloudyFadeLeft': {
    '0%': {
      opacity: 0,
      filter: 'blur(12px)',
      transform: 'translateX(-40px)',
    },
    '50%': {
      opacity: 0.6,
      filter: 'blur(6px)',
      transform: 'translateX(-10px)',
    },
    '100%': {
      opacity: 1,
      filter: 'blur(0)',
      transform: 'translateX(0)',
    },
  },
  letsGoButton: {
    backgroundColor: '#2E7DFF',
    color: '#fff',
    fontWeight: 700,
    padding: '8px 12px',
    borderRadius: '40px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    textTransform: 'uppercase',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      backgroundColor: '#1C64D1',
    },
  },
  iconWrapper: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: '$pulse 2s infinite ease-in-out',
  },
  icon: {
    color: '#2E7DFF',
    fontSize: '20px',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.6)',
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
    },
  },
}));

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '100px 20px',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'space-around',
    color: 'black',
  },
  revealWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: `clip-path 0.6s ease-in-out`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  companyName: {
    fontSize: '44px',
    fontWeight: 700,
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
  },
  nextButtonWrapper: {
    position: 'absolute',
    bottom: '100px',
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  logo: {
    objectFit: 'contain',
    width: '90%',
    maxWidth: '520px',
    maxHeight: '256px',
  },
  nextButton: {
    color: 'white',
    border: '1px solid',
    width: '70%',
  },
}));
