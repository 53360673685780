import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { MuteOffIcon } from '../../common/assets/playerIcons/MuteOffIcon';
import { MuteOnIcon } from '../../common/assets/playerIcons/MuteOnIcon';
import { EventNames } from '../../common/constants/constants';
import { AuthFormStrings } from '../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { useWelcomeVideo } from './WelcomeVideo.styles';
import { setHideCrispChat } from '../../store/slices/ui';
import { useTrackEvent } from '../../common/hooks/useTrackEvent';

interface WelcomeVideoPageProps {
  handleNextStep: () => void;
}

const WelcomeVideoPage: FC<WelcomeVideoPageProps> = ({ handleNextStep }) => {
  const classes = useWelcomeVideo({ clientHeight: window.innerHeight, isIos: isIOS });
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { firstName, lastName, email, phoneNumber } = useTypedSelector((state) => state.me);

  const [isWelcomeVideoMuted, setIsWelcomeVideoMuted] = useState(false);
  const [showSkipBtn, setShowSkipBtn] = useState(false);

  const refVideo = useRef<HTMLVideoElement | null>(null);
  const muteBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    dispatch(setHideCrispChat(true));
    trackEvent({ action: EventNames.welcome_video_page_view });
    return () => {
      dispatch(setHideCrispChat(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVideoLoaded = async () => {
    if (!refVideo.current) {
      return;
    }

    refVideo.current.play();
  };

  const handleOnEnded = () => {
    if (refVideo.current) {
      refVideo.current.currentTime = refVideo.current.currentTime - 0.1;
      trackEvent({
        action: EventNames.welcome_video_watched,
        location: window.location.href,
        accountId,
        firstName,
        lastName,
        email: email,
        phoneNumber: phoneNumber,
      });
      handleNextStep();
    }
  };

  const handleMutedClick = () => {
    setIsWelcomeVideoMuted((prevState) => !prevState);
  };

  const handleSkipClick = () => {
    dispatch(setHideCrispChat(false));
    trackEvent({
      action: EventNames.SkipButtonClick,
      location: window.location.href,
      accountId,
      firstName,
      lastName,
      email: email,
      phoneNumber: phoneNumber,
      time: refVideo?.current?.currentTime,
    });
    trackEvent({
      action: EventNames.welcome_video_skipped,
      location: window.location.href,
      accountId,
      firstName,
      lastName,
      email: email,
      phoneNumber: phoneNumber,
      time: refVideo?.current?.currentTime,
    });

    handleNextStep();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSkipBtn(true);
    }, 3000);
  }, []);

  return (
    <Box
      className={classes.welcomeVideoWrapper}
      style={{
        height: '100%',
      }}
    >
      <Box className={classes.banner}>
        <Typography className={classes.bannerTitle}>
          {firstName ? `${AuthFormStrings.Welcome} ${firstName}!` : 'Uploading a Great Video is Easy!'}
        </Typography>
        <Typography className={classes.bannerText}>Watch this video to learn more...</Typography>
      </Box>

      <Box className={classes.btnWrapper}>
        <IconButton ref={muteBtnRef} className={classes.muteIcon} size="small" onClick={handleMutedClick}>
          {isWelcomeVideoMuted ? <MuteOnIcon /> : <MuteOffIcon />}
        </IconButton>

        {showSkipBtn && (
          <IconButton className={classes.skipIcon} size="small" onClick={handleSkipClick}>
            <Typography className={classes.skipText}>Skip</Typography>
            <ArrowForwardIcon />
          </IconButton>
        )}
      </Box>
      <video
        playsInline
        onEnded={handleOnEnded}
        autoPlay
        muted={isWelcomeVideoMuted}
        className={classes.welcomeVideo}
        ref={refVideo}
        crossOrigin="anonymous"
        onLoadedData={onVideoLoaded}
        poster={currentCampaign?.welcomeVideoUrl?.replace(/\....$/, '.jpg').replace('/upload/', '/upload/so_0,')}
      >
        <source src={currentCampaign?.welcomeVideoUrl} type="video/mp4" />
      </video>
    </Box>
  );
};

export default WelcomeVideoPage;
