import { Box, Typography } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { useIncentiveBlockStyles } from './IncentiveBlock.styles';
// import { QuestionsComponent } from '../QuestionsComponent/QuestionsComponent';
import { PostUserQuestions } from '../../../store/slices/ui';
import { FC, useState } from 'react';
import { CampaignTypes } from '../../../api/models/rewards';
import { useClientType } from '../../../services/hooks/useClientType';
import { UserInfoBlock } from '../Layout/UserInfoBlock/UserInfoBlock';
import { updateUserRequest } from '../../../store/slices/me';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { EventNames } from '../../constants/constants';
import { RatingSelector } from '../RatingSelector/RatingSelector';

enum IncentiveBlockStrings {
  WeAppreciateYou = 'We appreciate you!',
  LearnMore = 'Learn more ',
  DefaultIncentiveText = 'about the $1,000 Monthly Giveaway',
  FooterNote = 'Do you want to capture videos for your business? ',
  FooterAction = 'Learn More \u25B8',
  Question1 = 'Do you plan on visiting us again?',
  Question2 = 'Would you refer us to friends?',
  YourInToWin = 'You’re in to win!',
  Youhavebeenentered = 'You’ve been entered!',
  GoodLuck = 'Good luck, we’re cheering for you!',
  SweepStakeText1 = 'You’ll also be entered in the',
  SweepStakeText2 = '$1,000 Monthly Giveaway!',
}

export const IncentiveBlock: FC<{ showQuestions?: boolean; showTopBanner?: boolean; isCompleteScreen?: boolean }> = ({
  showQuestions,
  isCompleteScreen = false,
}) => {
  const classes = useIncentiveBlockStyles();
  const { id } = useTypedSelector((state) => state.me);
  const { account } = useTypedSelector((state) => state.account);
  const { items, isLoading } = useTypedSelector((state) => state.ui.questions);
  const shouldHide = localStorage.getItem('hideInfoBlock');
  const { currentCampaign, primaryCampaign } = useTypedSelector((state) => state.rewards);
  const [isQS1Visible, setIsQS1Visible] = useState(!!shouldHide && !items.length && true);
  const [isQS1rendered, setIsQS1Rendered] = useState(!!shouldHide && !items.length && true);
  const [isQS2Visible, setIsQS2Visible] = useState(!!shouldHide && items.length && true);
  const [isQS2rendered, setIsQS2Rendered] = useState(!!shouldHide && items.length && true);

  const [isInfoBlockVisible, setIsInfoBlockVisible] = useState(!items.length && !shouldHide);
  const [isInfoBlockRendered, setIsInfoBlockRendered] = useState(!items.length && !shouldHide);

  const isCustomRewardCampaign = currentCampaign?.campaignType === CampaignTypes.CUSTOM_REWARD;
  const isMonthlyGiveawayCampaign = currentCampaign?.campaignType === CampaignTypes.MONTHLY_GIVEAWAY;
  const isNoRewardCampaign = currentCampaign?.campaignType === CampaignTypes.NO_REWARD;
  const { trackEvent } = useTrackEvent();

  const emailRequired = currentCampaign ? !!currentCampaign?.emailRequired : !!primaryCampaign?.emailRequired;

  const updateEmail = async (email: string) => {
    await dispatch(
      updateUserRequest({
        email: email,
        userId: id,
      }),
    );
  };

  //   const questions = [IncentiveBlockStrings.Question1, IncentiveBlockStrings.Question2];
  const { isUVMClient } = useClientType();

  const dispatch = useAppDispatch();
  const hasUserCompletedQuestions = !!(items.length > 1) && !isLoading;
  const handleAnswerClick = (answer: string, question: string) => {
    dispatch(
      PostUserQuestions({
        id,
        answer,
        question,
      }),
    );
  };
  const handleInfoBlockTransitionEnd = () => {
    if (!isInfoBlockVisible) {
      setIsInfoBlockRendered(false); // Remove the component after transition
    }
    setIsQS1Visible(true);
    setIsQS1Rendered(true);
  };
  // const dimissedPropertyNames = ['box-shadow', 'background-color'];
  const handleTransitionEnd = (e: any) => {
    if (e.target !== e.currentTarget) return;
    if (e.propertyName !== 'opacity') return;
    // console.log('event.propertyName', event.propertyName);
    // if ( dimissedPropertyNames.includes(event.propertyName) ) {
    //   return;
    // }
    if (!isQS1Visible) {
      setIsQS1Rendered(false); // Remove the component after transition
    }
    setIsQS2Visible(true);
    setIsQS2Rendered(true);
  };
  const handleTransitionEnd2 = (e: any) => {
    if (e.target !== e.currentTarget) return;
    if (e.propertyName !== 'opacity') return;
    // console.log('event.propertyName', event.propertyName);
    // if (dimissedPropertyNames.includes(event.propertyName) ) {
    //   return;
    // }
    if (!isQS2Visible) {
      setIsQS2Rendered(false); // Remove the component after transition
    }
  };
  return (
    <>
      {isCompleteScreen && hasUserCompletedQuestions && (
        <Box className={classes.titleContainer}>
          {/* <ReceivedIcon color="#058901" /> */}
          {isMonthlyGiveawayCampaign && (
            <Box display="flex" alignItems={'center'} justifyContent={'center'} flexDirection={'column'} mb={'10px'}>
              <Typography className={classes.title}>{IncentiveBlockStrings.YourInToWin}</Typography>
              <Typography className={classes.subTitle}>{IncentiveBlockStrings.GoodLuck}</Typography>
            </Box>
          )}
          {(isNoRewardCampaign || isCustomRewardCampaign) && (
            <Typography className={classes.title}>{IncentiveBlockStrings.WeAppreciateYou}</Typography>
          )}

          <Typography className={classes.accountName}>- {account.name}</Typography>
        </Box>
      )}
      {!isLoading && showQuestions && !hasUserCompletedQuestions && isInfoBlockRendered && (
        <Box
          className={[classes.contentContainer, isInfoBlockVisible ? '' : classes.hidden].join(' ')}
          onTransitionEnd={handleInfoBlockTransitionEnd}
          padding={'20%'}
        >
          <UserInfoBlock
            onClick={(email?: string) => {
              localStorage.setItem('hideInfoBlock', 'true');
              setIsInfoBlockVisible(false);
              setIsQS1Visible(true);
              setIsQS1Rendered(true);
              if (emailRequired && email) {
                updateEmail(email);
              }
            }}
          />
        </Box>
      )}

      {!isLoading && showQuestions && !hasUserCompletedQuestions && isQS1rendered && (
        <Box
          className={[classes.questionsContentContainer, isQS1Visible ? '' : classes.hidden].join(' ')}
          onTransitionEnd={handleTransitionEnd}
          paddingTop="30%"
        >
          <RatingSelector
            question={IncentiveBlockStrings.Question1}
            onClick={(answer: string) => {
              handleAnswerClick(answer, IncentiveBlockStrings.Question1);
              setIsQS1Visible(false);
              trackEvent({ action: EventNames.question_1 });
            }}
          />
          {/* <QuestionsComponent
            question={IncentiveBlockStrings.Question1}
            number="1"
            onClick={(answer: string) => {
              handleAnswerClick(answer, IncentiveBlockStrings.Question1);
              setIsQS1Visible(false);
              trackEvent({ action: EventNames.question_1 });
            }}
          /> */}
        </Box>
      )}
      {!isLoading && showQuestions && !hasUserCompletedQuestions && isQS2rendered && (
        <Box
          className={[classes.questionsContentContainer, isQS2Visible ? '' : classes.hidden].join(' ')}
          onTransitionEnd={handleTransitionEnd2}
          paddingTop="30%"
          // padding={'20%'}
        >
          <RatingSelector
            question={IncentiveBlockStrings.Question2}
            onClick={(answer: string) => {
              handleAnswerClick(answer, IncentiveBlockStrings.Question2);
              setIsQS2Visible(false);
              trackEvent({ action: EventNames.question_2 });
            }}
          />
          {/* <QuestionsComponent
            question={IncentiveBlockStrings.Question2}
            number="2"
            onClick={(answer: string) => {
              handleAnswerClick(answer, IncentiveBlockStrings.Question2);
              setIsQS2Visible(false);
              trackEvent({ action: EventNames.question_2 });
            }}
          /> */}
        </Box>
      )}

      <Box className={classes.contentContainer}>
        {/* <Typography className={styles.mainMessageTitle}>
            {SharePageStrings.YouAreAllSet.replace(
              '{name}',
              me.firstName ? me.firstName : shareStory.userName.split(' ')[0],
            )}
          </Typography>
          <Typography className={styles.mainMessageText}>
            {SharePageStrings.ThankYou.replace('{name}', account.name)}
          </Typography> */}

        {/* <Divider className={classes.divider} />

        <Typography className={classes.bottomText}>
          <a href="https://www.socialv.io" target="_blank" style={{ color: '#2D11AD', fontWeight: 'bold' }}>
            {IncentiveBlockStrings.LearnMore}
          </a>
          {IncentiveBlockStrings.DefaultIncentiveText}
        </Typography> */}

        {/*  Share stuff
          
          <Typography className={styles.bottomText}>{SharePageStrings.WouldYouShareVideo}</Typography>

          <Button
            variant="outlined"
            href={`${shareStory.videoPageUrl}&share=true`}
            className={styles.shareButton}
            fullWidth
          >
            {SharePageStrings.ShareVideo}
          </Button> */}

        {/* <Divider className={classes.divider} /> */}
      </Box>
      <Box
        position={'absolute'}
        bottom={'15%'}
        left="0"
        display="flex"
        alignItems={'center'}
        justifyContent={'center'}
        width="100%"
      >
        {(isMonthlyGiveawayCampaign || (isCustomRewardCampaign && !isUVMClient)) && (
          <Typography className={classes.bottomText}>
            <a
              href="http://www.socialvenu.com/give"
              target="_blank"
              style={{
                color: '#2D11AD',
                fontWeight: 'bold',
                pointerEvents: isCompleteScreen ? 'auto' : 'none',
              }}
            >
              {IncentiveBlockStrings.LearnMore}
            </a>
            {IncentiveBlockStrings.DefaultIncentiveText}
          </Typography>
        )}
        {/* {isCustomRewardCampaign && !isUVMClient && (
          <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width="100vw">
            <Typography className={classes.getStartedText}>{IncentiveBlockStrings.SweepStakeText1}</Typography>
            <Typography className={classes.sweepStakeTitle}>{IncentiveBlockStrings.SweepStakeText2}</Typography>
            <a
              href="http://www.socialvenu.com/give"
              target="_blank"
              style={{ color: '#2D11AD', fontWeight: 'bold', pointerEvents: isCompleteScreen ? 'auto' : 'none' }}
            >
              {IncentiveBlockStrings.LearnMore}
            </a>
          </Box>
        )} */}
        {/* <Typography className={classes.bottomText}>
          {IncentiveBlockStrings.FooterNote}
          <a href="https://www.socialv.io" target="_blank" style={{ color: '#2D11AD', fontWeight: 'bold' }}>
            {IncentiveBlockStrings.FooterAction}
          </a>
        </Typography> */}
      </Box>
    </>
  );
};
