/* eslint-disable @next/next/no-img-element */
import React, { FC, useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAnimatedTextStyles, useStyles } from './LaunchScreen.styles';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { getRevealBackground } from './LaunchScreen.helpers';
import { SignUpScreenStrings } from '../../common/localization/en';
import { useTrackEvent } from '../../common/hooks/useTrackEvent';
import { EventNames } from '../../common/constants/constants';
import { SocialVenuLogo } from '../../common/assets/SocialvLogo';

// const TEXT_PADDING = 40;

interface Props {
  isLoading: boolean;
  setIsLaunchScreen: (isLaunchScreen: boolean) => void;
}

const LaunchScreen: FC<Props> = ({ isLoading, setIsLaunchScreen }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const classes = useAnimatedTextStyles();

  const {
    // account: { logo, name },
    accountAttributes: { properties },
  } = useTypedSelector((state) => state.account);

  const reveal = properties?.['webapp.launch-screen']?.reveal;
  // const type = properties?.['webapp.launch-screen']?.type;

  const brandColor = properties?.['webapp.config']?.['primary-color'];
  const splashImage = properties?.['webapp.cards-welcome']?.desktop.original;
  const revealValue = reveal ? reveal : splashImage ? 'splash' : 'branding';
  // const launchType = type ? type : logo ? 'logo' : 'name';

  // const coverBackground = getCoverBackground(properties?.['webapp.launch-screen']?.cover, brandColor);
  const revealBackground = getRevealBackground(revealValue, brandColor, splashImage);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const [textFadeOut, setTextFadeOut] = useState(false);
  const [showSVLogo, setShowSVlogo] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  // const [hasMeasured, setHasMeasured] = useState(false);
  const [showText, setShowText] = useState(false);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent({ action: EventNames.opening_animation_start });
    return () => {
      trackEvent({ action: EventNames.opening_animation_end });
    };
  }, []);

  useEffect(() => {
    setIsLaunchScreen(true);
  }, [setIsLaunchScreen]);

  useEffect(() => {
    if (revealValue !== 'splash' || !splashImage) {
      setIsBackgroundImageLoading(false);
    } else {
      const image = new Image();
      image.src = revealBackground;
      image.onload = () => {
        setTimeout(() => {
          setIsBackgroundImageLoading(false);
        }, 300);
      };
    }
  }, [revealBackground, revealValue, splashImage]);
  useEffect(() => {
    const timer = setTimeout(() => setTextFadeOut(true), 5000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => setShowText(true), 550);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => setShowSVlogo(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let redirectTimer: ReturnType<typeof setTimeout>;

    if (!isLoading && !isBackgroundImageLoading) {
      redirectTimer = setTimeout(() => {
        setShowNextButton(true);
      }, 3000);
    }

    return () => {
      clearTimeout(redirectTimer);
    };
  }, [dispatch, isBackgroundImageLoading, isLoading, setShowNextButton]);

  useEffect(() => {
    const timeout = setTimeout(() => setAnimateIn(true), 100); // slight delay lets browser register the element first
    return () => clearTimeout(timeout);
  }, []);

  // const containerElement = useRef<HTMLDivElement>(null);
  // const textElement = useRef<HTMLParagraphElement>(null);

  // useEffect(() => {
  //   const container = containerElement.current;
  //   const textContainer = textElement.current;
  //   let fontSize = 44;

  //   if (container && textContainer) {
  //     const textNodes = textContainer.children;

  //     for (let i = 0; i < textNodes.length; i++) {
  //       const textNode = textNodes.item(i) as HTMLSpanElement;
  //       let textWidth = textNode.offsetWidth;

  //       while (textWidth >= container.offsetWidth - TEXT_PADDING) {
  //         textContainer.style.fontSize = `${fontSize}px`;
  //         textWidth = textNode.offsetWidth;
  //         --fontSize;
  //       }
  //     }
  //   }
  //   setHasMeasured(true);
  // }, [containerElement, textElement]);

  const handleClick = () => {
    setIsLaunchScreen(false);
  };

  return (
    <>
      <Box
        className={styles.container}
        sx={{
          background: `url(https://storage.googleapis.com/sv-dev-assets/48ff892b-7ae6-406f-a2d4-3e55c6b63835/3778f138-1d4d-422a-bffb-25d816a03fcb.png)`,
          backgroundPositionX: '10%',
          backgroundSize: 'cover',
        }}
        // ref={containerElement}
      >
        {showText && (
          <Box>
            <Typography
              className={[classes.inspireText, textFadeOut ? classes.cloudyFadeOutText : classes.fadingText].join(' ')}
            >
              {SignUpScreenStrings.InspirationText}
            </Typography>
          </Box>
        )}
        {showSVLogo && (
          <Box className={classes.fadingText} marginTop="40px">
            <SocialVenuLogo />
          </Box>
        )}
        <Box
          className={styles.revealWrapper}
          sx={{
            clipPath:
              isLoading || isBackgroundImageLoading ? 'ellipse(0% 0% at 100% 100%)' : 'ellipse(140% 180% at 100% 100%)',
          }}
        ></Box>
        {/* <Box className={styles.nextButtonWrapper}>
          {!isLoading && !isBackgroundImageLoading && showNextButton && (
            <Button
              className={styles.companyName}
              onClick={handleClick}
              variant="contained"
              sx={{ textShadow: revealValue === 'splash' ? `2px 2px 0px ${brandColor}` : 'unset', color: '#FFF' }}
            >
              {SignUpScreenStrings.LetsGo}
            </Button>
          )}
        </Box> */}
        <Box className={styles.nextButtonWrapper}>
          {!isLoading && !isBackgroundImageLoading && showNextButton && (
            <Button
              className={`${classes.letsGoButton} ${animateIn ? classes.buttonZoomBounce : ''}`}
              onClick={handleClick}
            >
              {SignUpScreenStrings.LetsGo}
              <span className={classes.iconWrapper}>
                <ArrowForwardIcon className={classes.icon} />
              </span>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LaunchScreen;
