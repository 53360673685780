import React from 'react';
import { Box, Typography } from '@mui/material';

export const ExpiredLinkScreen: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
        textAlign: 'center',
      }}
    >
      <Typography
        component="div"
        sx={{
          fontSize: { xs: '64px', sm: '80px' },
          mb: { xs: 2, sm: 3 },
        }}
      >
        😞
      </Typography>

      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          color: '#333',
          mb: { xs: 2, sm: 3 },
          fontSize: { xs: '1.9rem', sm: '2.2rem' },
        }}
      >
        We’re sorry…
      </Typography>

      <Typography
        sx={{
          color: '#555',
          fontSize: { xs: '1rem', sm: '1.125rem' },
          maxWidth: '460px',
          lineHeight: 1.6,
        }}
      >
        The QR Code/link used has expired
        <br />
        and is no longer accepting videos.
      </Typography>
    </Box>
  );
};
