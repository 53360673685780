import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const options = [
  { label: 'Doubt it', color: '#B0301C' },
  { label: ' ', color: '#F5A623' },
  { label: 'Possibly', color: '#E2CA16' },
  //#E2CA16
  //#84BD09
  { label: ' ', color: '#84BD09' },
  { label: 'Absolutely', color: '#7ED321' },
];

interface RatingSelectorProps {
  question: string;
  onClick: (value: string) => void;
}

export const RatingSelector: React.FC<RatingSelectorProps> = ({ question, onClick }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const handleClick = () => {
    if (selectedIndex) {
      onClick((selectedIndex + 1).toString());
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 360,
        mx: 'auto',
        textAlign: 'center',
        p: 3,
        backgroundColor: '#fff',
        borderRadius: '12px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#333', mb: 1 }}>{question}</Typography>

      <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#888', mb: 2 }}>TAP TO SELECT</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', mb: 3 }}>
        {options.map((opt, i) => {
          const isSelected = selectedIndex === i;

          return (
            <Box
              key={i}
              onClick={() => setSelectedIndex(i)}
              sx={{
                cursor: 'pointer',
                textAlign: 'center',
                width: '68px',
                height: '68px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  width: 42,
                  height: 42,
                  borderRadius: '50%',
                  border: `6px solid ${opt.color}`,
                  backgroundColor: isSelected ? opt.color : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                }}
              >
                {isSelected && (
                  <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    style={{ stroke: 'white', strokeWidth: 3, fill: 'none' }}
                  >
                    <path
                      d="M5 13l4 4L19 7"
                      style={{
                        strokeDasharray: 24,
                        strokeDashoffset: 24,
                        animation: 'drawCheck 0.4s ease-out forwards',
                      }}
                    />
                  </svg>
                )}
              </Box>
              <Typography sx={{ fontSize: '12px', color: '#444', mt: 1, height: '13px' }}>{opt.label}</Typography>
            </Box>
          );
        })}
      </Box>

      <Button
        variant="contained"
        fullWidth
        disabled={selectedIndex === null}
        onClick={handleClick}
        sx={{
          backgroundColor: '#2D11AD',
          color: '#fff',
          fontWeight: 600,
          borderRadius: '8px',
          textTransform: 'none',
          py: 1.2,
          '&:hover': {
            backgroundColor: '#2D11AD',
          },
        }}
      >
        Next
      </Button>

      {/* Keyframes for checkmark drawing */}
      <style>
        {`
          @keyframes drawCheck {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
      </style>
    </Box>
  );
};
