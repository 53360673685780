import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    height: 'var(--app-height)',
    minHeight: '550px',
  },
  navPanel: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    transform: 'translateX(-50%)',
    left: '50%',
    zIndex: 2,
  },
  screenWrapper: {
    width: '100%',
    height: '100%',
    background:
      'url(https://storage.googleapis.com/sv-dev-assets/48ff892b-7ae6-406f-a2d4-3e55c6b63835/4cde9c95-fbc0-4818-924d-e3e6f86caa74.png)',
    backgroundPositionX: '10%',
    backgroundSize: 'cover',
  },
  launchScreenWrapper: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
}));
