import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<unknown, { color?: string }>(() => ({
  container: {
    width: '90vw',
    maxWidth: '500px',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconBreath: {
    animation: '$breath 2.5s ease-in-out infinite',
    color: '#2D11AD',
    fontSize: '28px',
  },
  '@keyframes breath': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.08)' },
    '100%': { transform: 'scale(1)' },
  },

  iconCenter: {
    position: 'absolute',
    top: '50%',
    left: '37%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconPulse: {
    animation: '$pulse 1.2s ease-in-out infinite',
    color: '#2D11AD',
    fontSize: '28px',
  },

  '@keyframes pulse': {
    '0%': { transform: 'scale(1)', opacity: 1 },
    '50%': { transform: 'scale(1.25)', opacity: 0.75 },
    '100%': { transform: 'scale(1)', opacity: 1 },
  },
  iconPulseWaveWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    zIndex: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      backgroundColor: 'rgba(45, 17, 173, 0.3)', // brand color with transparency
      animation: '$wavePulse 2s ease-out infinite',
      zIndex: -1,
    },
  },
  progressWrapper: {
    width: '100%',
    height: '16vw',
    maxHeight: '86px',
    borderRadius: '9px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '14px',
    paddingRight: '8px',
  },
  uploadText: {
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: 'clamp(20px,  5vw, 32px)',
    color: '#2D11AD',
  },
  keepOpenText: {
    textAlign: 'center',
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: 'clamp(16px,  3.9vw, 24px)',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
  uploadCompleteText: ({ color }) => ({
    fontSize: 'clamp(14px,  4vw, 18px)',
    fontWeight: 500,
    lineHeight: '20px',
    color,
  }),
  thanksText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
    textAlign: 'center',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
  stepsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
  },
  stepText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  step: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadErrorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadFailText: {
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#B42318',
  },
  weakConnectText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
}));
