import { makeStyles } from '@mui/styles';

export const useIncentiveBlockStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'white',
    width: '100%',
    height: '100vh',
    maxHeight: 'calc(100vh - 40px)',
    padding: '12px 15px 0',
    minHeight: '550px',
  },
  getStartedText: {
    color: '#475467',
    fontSize: 'clamp(18px, 5vw, 27px)',
    textAlign: 'center',
    fontWeight: 400,
    lineHeight: '20px',
  },
  sweepStakeTitle: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'center',
    paragraph: '24px',
    color: '#475467',
    margin: '8px 8px',
  },
  sweepStakeDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    paragraph: '20px',
    color: '#475467',
    marginBottom: '16px',
  },

  accountName: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px',
    color: '#2D11AD',
    textAlign: 'center',
  },

  titleContainer: {
    // display: 'flex',
    alignItems: 'center',
    marginTop: '5vh',
    marginBottom: '10vh',
    opacity: 1, // Fully visible
    transition: 'opacity 0.5s ease-in-out', // Smooth fade
  },
  hidden: {
    opacity: 0,
  },

  title: {
    fontSize: 'clamp(30px, 5.6vw, 30px)',
    fontWeight: 650,
    color: '#2D11AD',
  },
  subTitle: {
    fontSize: 'clamp(20px, 4vw, 20px)',
    fontWeight: 650,
    color: '#2D11AD',
  },

  contentContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  questionsContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'opacity 0.5s ease-in-out',
  },

  mainMessageTitle: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    color: '#2D11AD',
    textAlign: 'center',
    fontWeight: '700',
    lineHeight: '4.5vh',
    opacity: '0.8',
  },

  mainMessageText: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    color: '#2D11AD',
    fontWeight: '500',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    opacity: '0.8',
  },

  divider: {
    width: '255px',
    margin: '2vh 0',
    borderColor: '#E4E4E4',
  },

  bottomText: {
    fontSize: '12px',
    width: '100%',
    textAlign: 'center',
    color: '#475467',
    marginBottom: '2vh',
    marginTop: '2vh',
    whiteSpace: 'pre-wrap',
    padding: '0px 55px',
  },

  shareButton: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    fontWeight: '600',
    color: '#2D11AD',
    borderColor: '#2D11AD',
    '&:hover': {
      color: '#2D11AD',
      borderColor: '#2D11AD',
    },
  },
}));
